import 'bootstrap';
import '../scss/idm.scss';
import {Tooltip} from 'bootstrap';

import netteForms from './form.js';
import naja from 'naja';
import {LoadingIndicatorExtension} from './naja/spinner.js';
import {DownloadFileExtension} from "./naja/download.js";

netteForms.initOnLoad();

naja.registerExtension(new LoadingIndicatorExtension());
naja.registerExtension(new DownloadFileExtension());
naja.initialize();

function showPassword(elem, show) {
    let x = elem.closest('.input-group');
    if (!x) {
        return;
    }

    let input = x.querySelector('input');
    let svgIconLink = elem.parentElement.querySelector('svg > use');
    let svgLinkHref = svgIconLink.getAttribute('xlink:href').split('.svg#')[0];
    if (show) {
        svgIconLink.setAttribute('href', svgLinkHref + '.svg#bi-eye-slash');
        input.type = "text";
    } else {
        svgIconLink.setAttribute('href', svgLinkHref + '.svg#bi-eye');
        input.type = "password";
    }
}

document.querySelectorAll('form a.show-password, form span.show-password').forEach(elem => {
    elem.addEventListener('mousedown', function () {
        showPassword(elem, true);
    });
    elem.addEventListener('mouseup', function () {
        showPassword(elem, false);
    });
    elem.addEventListener('mouseout', function () {
        showPassword(elem, false);
    });
    elem.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
    });
    elem.addEventListener('touchstart', function () {
        console.log('touchstart');
        showPassword(elem, true);
    });
    elem.addEventListener('touchmove', function () {
        console.log('touchmove');
        showPassword(elem, false);
    });
    elem.addEventListener('touchend', function () {
        console.log('touchend');
        showPassword(elem, false);
    });
    elem.addEventListener('touchcancel', function () {
        console.log('touch');
        showPassword(elem, false);
    });
});

let config = {
    closeAfterSelect: true,
    hideSelected: false,
    valueField: 'value',
    labelField: 'text',
    maxItems: 1,
};

document.querySelectorAll('form input.autocomplete, form select.autocomplete').forEach(async function (elem) {
    let TomSelect = await import('tom-select');
    let elemConfig = {};

    const preload = elem.dataset.preload || false;
    if (elem.dataset.ajaxUrl) {
        Object.assign(elemConfig, {
            preload: preload,
            load: function (query, callback) {
                let self = this;

                if (preload === 'focus' && self.loading > 1) {
                    callback();
                    return;
                }

                let host = window.location.protocol + "//" + window.location.host;
                let url = new URL(self.input.dataset.ajaxUrl, host);
                url.searchParams.append(self.input.dataset.ajaxQuery || 't', query);

                fetch(url)
                    .then(response => response.json())
                    .then(json => {
                        callback(json);
                        if (preload === 'focus') {
                            self.settings.load = null;
                        }
                    }).catch(() => {
                    callback();
                });

            }
        });
    }

    if (elem.multiple) {
        elemConfig.closeAfterSelect = false;
        elemConfig.hideSelected = true;
    }

    if (elem.dataset.render) {
        let renderTemplates = JSON.parse(elem.dataset.render);
        elemConfig.render = {};
        const regex = /\${([a-zA-Z]+)}/g;

        for (let key in renderTemplates) {
            elemConfig.render[key] = function (item, escape) {
                console.log(renderTemplates[key]);
                return renderTemplates[key].replace(regex, ((match, p1) => {
                    return escape(item[p1] || '');
                }));
            };
        }
    }

    new TomSelect.default(elem, Object.assign({}, config, elemConfig));
});

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

export class DownloadFileExtension {
    initialize(naja) {
        naja.addEventListener('start', this.downloadFile.bind(this));
    }

    async downloadFile(event) {
        const response = await event.detail.promise;

        if (!response || !response.ok) {
            return;
        }

        if (!response.headers.has('content-type')) {
            return;
        }

        const contentDisposition = response.headers.get('content-type');
        if (contentDisposition !== 'application/octet-stream') {
            return;
        }

        // Create a blob from the response stream
        const fileBlob = await response.blob();

        // Create a link element
        const objectUrl = URL.createObjectURL(fileBlob);
        const a = document.createElement('a');

        // Set link attributes and click to start download
        a.href = objectUrl;
        a.download = this.getFileNameFromHeader(response.headers.get('content-disposition'));
        a.style.display = 'none';

        // Add to the document and click
        document.body.appendChild(a);
        a.click();

        // Cleanup
        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
    }

    getFileNameFromHeader(contentDisposition) {
        const filenameToken = `filename*=UTF-8''`;
        const filenameStar = contentDisposition.split(';').find(n => n.trim().indexOf(filenameToken) === 0);

        if (filenameStar) {
            return decodeURIComponent(filenameStar.trim().replace(filenameToken, ''));
        }

        const filenameRegexp = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = contentDisposition.match(filenameRegexp);

        if (matches != null && matches[1]) {
            let filename = matches[1];
            if (filename[0] === '"' && filename[filename.length - 1] === '"') {
                filename = filename.substring(1, filename.length - 1);
            }

            return filename;
        }

        throw new Error('Cannot parse Content-Disposition header');
    }
}

export class LoadingIndicatorExtension {
    indicatorId = 'ajax-spinner';
    indicator = null;

    constructor(defaultLoadingIndicatorSelector) {
        this.defaultLoadingIndicatorSelector = defaultLoadingIndicatorSelector;
    }

    initialize(naja) {
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', () => {
                this.defaultLoadingIndicator = document.querySelector(this.defaultLoadingIndicatorSelector);
                this._createIndicator();
            });
        } else {
            this.defaultLoadingIndicator = document.querySelector(this.defaultLoadingIndicatorSelector);
            this._createIndicator();
        }

        naja.uiHandler.addEventListener('interaction', this.locateLoadingIndicator.bind(this));
        naja.addEventListener('start', this.showLoader.bind(this));
        naja.addEventListener('complete', this.hideLoader.bind(this));
    }

    locateLoadingIndicator(event) {
        const loadingIndicator = event.detail.element.closest('[data-loading-indicator]');
        event.detail.options.loadingIndicator = loadingIndicator || this.defaultLoadingIndicator;
    }

    showLoader(event) {
        this.indicator.classList.add('d-flex');
    }

    hideLoader(event) {
        this.indicator.classList.remove('d-flex');
        this.indicator.style.display = 'none';
    }

    _createIndicator() {
        this.indicator = document.createElement('div');
        this.indicator.id = this.indicatorId;
        this.indicator.style.display = 'none';
        this.indicator.classList.add('align-items-center');
        this.indicator.classList.add('justify-content-center');

        let spinner = document.createElement('div');
        spinner.role = 'status';
        spinner.classList.add('spinner-border');
        spinner.classList.add('text-primary');
        spinner.style = 'width: 3rem; height: 3rem;';

        let spinnerText = document.createElement('span');
        spinnerText.textContent = 'Loading...';
        spinnerText.classList.add('visually-hidden');
        spinner.appendChild(spinnerText);

        this.indicator.appendChild(spinner);

        document.body.appendChild(this.indicator);
    }

}
